// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  dissableCache:false,
  
  

//production: false,
/*
  testsite:[{testurl:['mccarthytoyota2022.elianceprelive.co.za','mccarthytoyota.mccarthy.co.za', 'localhost','10.0.0.143','105.244.76.148' ], dataurl:'www.mccarthytoyota.co.za'}],
  GearsApi_url:'https://api.gearsauto.co.za/mccarthy/',
  storageurl: "https://Gearscdn.azureedge.net/gears-live/",
*/

  GearsApi_url: 'https://gearsapi.elianceprelive.co.za/',
  storageurl: "https://gearsauto.blob.core.windows.net/gears-dev/",
  testsite:[{testurl:['mccarthytoyota2022.elianceprelive.co.za','mccarthytoyota.mccarthy.co.za', 'localhost','10.0.0.143','105.244.76.148' ], dataurl:'mccarthytoyota.elianceprelive.co.za'}],


  initfee: 500,
  adminfee: 1500,
  API_ID: "CSE",
  API_KEY: "a579263139d743fba868ed97f873a01c9f58f605c004439b9b859b17f63022f6",
  API_URL: "gearsapi.elianceprelive.co.za",
  //API_URL: "api.gearsauto.co.za/mccarthy",
  SignioURL: 'https://stage2.signio.co.za/ThirdPartyIntegration/application',
  SignioAuth: 'britehouse:--06%left%DECEMBER%weather%80--',
  SignioAuthToken: '86d13fbc-5e5f-4948-acb9-73269b7c519d',
  FinanceInterest:11,
  FinanceMonths:72,
  FinanceDeposit:10,
  FinanceBalloon:10,

  NewsRedirect:null,
  facebookAppId: '731309600573232'
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
