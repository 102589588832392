import { isPlatformBrowser } from '@angular/common';
import { Component, Inject, OnInit, PLATFORM_ID } from '@angular/core';
import { ElementRef, Input } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { GearsApiService } from 'src/app/services/gearsapi.service';
import { ModalService } from '../modalservice.service';
import { SitesService } from 'src/app/services/sites.service';
import { CampaignsService } from 'src/app/services/campaigns.service';

@Component({
  selector: 'app-alerts',
  templateUrl: './alerts.component.html',
  styleUrls: ['./alerts.component.less']
})
export class AlertsComponent implements OnInit {
  @Input() id?: string;
  isOpen = false;
  private element: any;
  Data: any;
  site: any;
  Regions: any = [];
  Titles: any = [];
  formEnquire!: FormGroup;
  failedsubmit = false;
  constructor(@Inject(PLATFORM_ID) private platformId: Object, private siteService: SitesService, private formBuilder: FormBuilder, private modalService: ModalService, private el: ElementRef, private apiservice: GearsApiService, private campaignService: CampaignsService) {
    this.element = el.nativeElement;
  }

  ngOnInit(): void {
    this.formEnquire = this.formBuilder.group({
      title: [-1, Validators.min(0)],
      region: [null],
      name: [null, Validators.required],
      surname: [null, Validators.required],
      email: [null, [Validators.required, Validators.email, Validators.pattern(/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/)]],
      phone: [null, [Validators.required, Validators.pattern(/^(?:[\+][0-9])?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4}$/)]],
      marketing: [false],
      tcs: [false, Validators.requiredTrue]
    });
    if (isPlatformBrowser(this.platformId)) {
      this.modalService.add(this);
      document.body.appendChild(this.element);

      this.siteService.get().then((x: any) => {
        this.site = x;
      });

      this.apiservice.get('Titles', {
        PageSize: 9999,
        PageNumber: 1,
        OrderBy: ''
      }).then((x: any) => {
        this.Titles = x.body;
      });
      this.apiservice.get('Regions', {
        PageSize: 9999,
        PageNumber: 1,
        OrderBy: ''
      }).then((x: any) => {
        this.Regions = x.body;
      });

      this.element.addEventListener('click', (el: any) => {
        if (el.target.className === 'enquiremodal') {
          this.close();
        }
      });
    }
  }

  ngOnDestroy() {
    this.element.remove();
  }

  open(data: any = null) {
    if (isPlatformBrowser(this.platformId)) {
      this.element.style.display = 'block';
      document.body.classList.add('jw-modal-open');
      this.isOpen = true;
      this.Data = data;
    }
  }

  submit() {
    if (this.formEnquire.invalid) {
      this.failedsubmit = true;
    }
    else {
      var post = {
        Active: 1,
        ContactNo: this.formEnquire.controls['phone'].value,
        Deleted: 0,
        Email: this.formEnquire.controls['email'].value,
        Marketing: this.formEnquire.controls['marketing'].value,
        Name: this.formEnquire.controls['name'].value,
        SiteID_FK: this.site.SiteID_PK,
        TitleID_FK: this.formEnquire.controls['title'].value,
        Surname: this.formEnquire.controls['surname'].value,
        UtmCampaign: this.campaignService.getGoogleTracking(),
        searchParams: {
          RegionID_FK: this.Data['region']||null,
          DealershipID_FK: this.Data['dealership'],
          VehicleMakeID_FK: this.Data['make'] == '' ? undefined : this.Data['make'],
          VehicleSeriesID_FK: this.Data['series'],
          VehicleTransmissionID_FK: this.Data['geartype'],
          VehicleTypeID_FK: this.Data['bodytype'],
          VehicleFuelTypeID_FK: this.Data['fueltype'],
          MaxMileage: this.Data['kilomax'],
          MinMileage: this.Data['kilomin'],
          MinPrice: this.Data['pricemin'],
          MaxPrice: this.Data['pricemax'],
          MinYear: this.Data['yearmin'],
          MaxYear: this.Data['yearmax'],
        }
      }
      this.apiservice.post('Alerts', post).then((x: any) => {

        window.location.href = "/alerts/thankyou";

      });
    }
  }

  tcs() {
    this.modalService.open('tcs');
  }
  
  close() {
    if (isPlatformBrowser(this.platformId)) {
      this.element.style.display = 'none';
      document.body.classList.remove('jw-modal-open');
      this.isOpen = false;
    }
  }

}
