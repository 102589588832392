import { Component, Input, NgModule, OnInit,ElementRef, ViewChild, OnChanges } from '@angular/core';
import { environment } from 'src/environments/environment';

import { CommonModule } from '@angular/common';
import { DragScrollComponent, DragScrollItemDirective } from 'ngx-drag-scroll';

@Component({
  selector: 'app-slider',
  templateUrl: './slider.component.html',
  styleUrls: ['./slider.component.less']
})
export class SliderComponent implements OnInit, OnChanges {  
  constructor(private elementRef: ElementRef) { }
  public current = 0;

  @Input() public images:Array<any> = [];
  @Input() activeIndex:number = 0;
  @Input() public autoScroll:Boolean = true;
  @Input() public id:string = '';
  @Input() carouselStyling:string = '';
  @Input() labelBanner: boolean = false;
  @Input() customURL: string = '';
  intervalId:any;

  singleImage:any = true;

  
  loading:boolean = true;
  FeatureImgClass: any;
  currentIndex: number = 0;
  @ViewChild('badges', { read: DragScrollComponent }) ds!: DragScrollComponent;
  ngOnInit(): void {
    this.loading = false;
    if (this.images.length > 1) this.singleImage = false;
    if (this.singleImage===false) this.setAutoScroll();
  }

  ngOnChanges():void{
    this.dragscrollcurrentSlide(this.activeIndex);
  }

  setAutoScroll() {
    if (this.autoScroll) {
      this.intervalId = setInterval(this.autoClickButton, 5000);
      console.log('Start AutoScroll');
    }
    
  }
  clearAutoScroll() {
    if (this.autoScroll) {
      clearInterval(this.intervalId);
      this.intervalId = null;
      console.log('Stop AutoScroll');
    }
  }

  autoClickButton() {
    const button = document.getElementById('nextButt');
    if (button) {
        button.click();
    } else {
        console.error("Button not found!");
    }
  }
  imgadd(url: string) {
    if (!url.includes('http')) {
      return environment.storageurl + url;
    }
    else {
      return url;
    }
  }

  dragscrollnext() {
    if (this.currentIndex >= this.images.length-1) {
      if (this.currentIndex > 100 || this.carouselStyling?.includes('sideBySide')){
        this.dragscrollcurrentSlide(0);
      } else {
        this.images.push(...this.images);
      }
    }
    this.currentIndex++;
    this.ds.moveRight();
  }

  dragscrollprev() {
    if (this.currentIndex <= 0) {
      this.dragscrollcurrentSlide(this.images.length-1)
    } else {
      this.currentIndex--;
      this.ds ? this.ds.moveLeft() : console.warn('ds is undefined');
    }
  }

  dragscrollcurrentSlide(index : number) {
    this.currentIndex = index;
    this.ds ? this.ds.moveTo(index) : console.warn('ds is undefined');
  }

  dragscrollResize(x: any) {
    this.dragscrollcurrentSlide(x-1);
    this.dragscrollcurrentSlide(x);
  }

  currentSlide(index : number) {
    this.activeIndex = index;
  }

  next() {
    if (this.activeIndex !== this.images.length - 1) {
      this.activeIndex++;
    }
    else{
      this.activeIndex = 0
    }
  }

  prev() {
    if (this.activeIndex !== 0) {
      this.activeIndex--;
    }
    else{
      this.activeIndex = this.images.length - 1;
    }
  }

  indicatorButton(e:number) {
    let activeClasses = this.elementRef.nativeElement.querySelectorAll('#carouselIndicators'+this.id)
    if (!activeClasses[e]?.classList?.contains('active')){
      activeClasses.forEach(function (activeClass:any){
        if (activeClass?.classList?.contains('active')){
          activeClass.classList.remove('active');
        }
      })
    }
    this.activeIndex = e;
  }
}
@NgModule({
  declarations: [
    SliderComponent,
  ],
  imports:[
    CommonModule,
    DragScrollComponent,
    DragScrollItemDirective
    //DragScrollModule
  ],
  exports: [
    SliderComponent
  ]
})
export class SliderModule { }