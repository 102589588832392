import { HttpClient, HttpResponse, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { JwtHelperService } from '@auth0/angular-jwt';
import { catchError, Observable, share,shareReplay, take } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class GearsApiService {
  constructor(private http: HttpClient) {}

 running: { [id: string] : any; } = {};
 helper = new JwtHelperService();
  post(url: string, params: any) {
    const requestUrl = environment.GearsApi_url + url;
    return new Promise((resolve) => {
      this.http
        .post<any>(requestUrl, params)
        .pipe(take(1))
        .subscribe((x: any) => {
          resolve(x);
        });
    });
  }
  
  get(url: string, Params: any, cache: boolean = true) {
    cache = environment.dissableCache ? false : cache;
    return new Promise((resolve) => {
      var searchParams: any = {
        Active: 1,
        Deleted: 0,
        OrderBy: '',
        PageNumber: 1,
        PageSize: 100,
      };

      for (let key in Params) {
        if (
          Params[key] != null &&
          Params[key] != undefined &&
          Params[key] != ''
        ) {
          searchParams[key] = Params[key];
        }
      }
      const requestUrl = environment.GearsApi_url + url;

      if (sessionStorage.getItem(url + JSON.stringify(searchParams)) !== null) {
        resolve(
          JSON.parse(
            sessionStorage.getItem(url + JSON.stringify(searchParams)) || '{}'
          )
        );
      } else {
        if (this.helper.isTokenExpired(sessionStorage.getItem('token') || '')) {
          this.getAuth()
            .subscribe((x: any) => {
              sessionStorage.setItem('token', x.body.Data.AuthToken);
              if(this.running[ url + JSON.stringify(searchParams)]){
                this.running[ url + JSON.stringify(searchParams)]
                .subscribe((x: any) => {
                  x.count = x.headers.get('X-Count');
    
                 
                  resolve(x);
                });
              }
              else{
              this.running[ url + JSON.stringify(searchParams)] = this.http
                .get<any>(requestUrl, {
                  observe: 'response',
                  params: searchParams,
                }).pipe(shareReplay(1));
                this.running[ url + JSON.stringify(searchParams)]
                .subscribe((x: any) => {
                  x.count = x.headers.get('X-Count');
    
                  if (cache) {
                    sessionStorage.setItem(
                      url + JSON.stringify(searchParams),
                      JSON.stringify(x)
                    );
                  }
                  resolve(x);
                });
              }
            });
        } else {

          if(this.running[ url + JSON.stringify(searchParams)]){
            this.running[ url + JSON.stringify(searchParams)]
            .subscribe((x: any) => {
              x.count = x.headers.get('X-Count');

             
              resolve(x);
            });
          }
          else{
          this.running[ url + JSON.stringify(searchParams)] = this.http
            .get<any>(requestUrl, {
              observe: 'response',
              params: searchParams,
            }).pipe(shareReplay(1));
            this.running[ url + JSON.stringify(searchParams)]
            .subscribe((x: any) => {
              x.count = x.headers.get('X-Count');

              if (cache) {
                sessionStorage.setItem(
                  url + JSON.stringify(searchParams),
                  JSON.stringify(x)
                );
              }
              resolve(x);
            });
          }
        }
      }
    });
  }

  /*getlist(url: string, Params: any, cache: boolean = true) {
    var searchParams: any = { Active: 1, Deleted: 0, OrderBy: '', PageNumber: 1, PageSize: 100 };

    for (let key in Params) {
      if (Params[key] != null && Params[key] != undefined) {

        searchParams[key] = Params[key];
      }
    }
    const requestUrl = environment.GearsApi_url + url;

    if (storage.getItem(url + JSON.stringify(searchParams)) !== null) {
      return (JSON.parse(storage.getItem(url + JSON.stringify(searchParams)) || ''));
    }
    else {
      
      this.http
        .get<any>(requestUrl, {
          observe: 'response',
          params: searchParams
        }).pipe(take(1))
        .subscribe((x: any) => {
          x.count = x.headers.get('X-Count');

          if (cache) {

            storage.setItem(url + JSON.stringify(searchParams), JSON.stringify(x));
          }
          return (x);
        });
    }

  }*/


getAuth(){

  if(this.running[ "auth"]){

    return this.running[ "auth"];
  }
  else{
    this.running[ "auth"] = this.http
    .get<any>(environment.GearsApi_url + '/AuthToken', {
      observe: 'response',
      params: {
        APP_ID: environment.API_ID,
        API_KEY: environment.API_KEY,
        API_URL: environment.API_URL,
      },
    })
    .pipe(shareReplay(1));

    return this.running[ "auth"];
  }
  
}

  getSingle(url: string, id: string, searchParams: any = null, cache: boolean = true): any {
    cache = environment.dissableCache ? false : cache;
    const requestUrl = environment.GearsApi_url + url + '/' + id;
    return new Promise((resolve) => {
      if (sessionStorage.getItem(url + '/' + id) !== null) {
        resolve(
          JSON.parse(
            sessionStorage.getItem(url + '/' + id) || '{}'
          )
        );
      } else {
        if (this.helper.isTokenExpired(sessionStorage.getItem('token') || '')) {
          
            this.getAuth().subscribe((x: any) => {
              sessionStorage.setItem('token', x.body.Data.AuthToken);
              this.http.get<any>(requestUrl, {
                observe: 'response',
                params: searchParams,
              }).pipe(take(1))
              .subscribe((x: any) => {
                if (cache) {
                  sessionStorage.setItem(
                    url + JSON.stringify(searchParams),
                    JSON.stringify(x)
                  );
                }
                resolve(x);
              });
            });
        } else {
          
          this.running[ url + JSON.stringify(searchParams)] = this.http.get<any>(requestUrl, {
            observe: 'response',
            params: searchParams,
          }).pipe(take(1)).subscribe((x: any) => {
            if (cache) {
              sessionStorage.setItem(
                url + '/' + id,
                JSON.stringify(x)
              );
            }
            resolve(x);
          });
        }
      }
    });
  }

  downloadFile(url: string) {
    let headerObj = new HttpHeaders();
    headerObj.set('Content-Type', 'application/octet-stream');
    headerObj.set('responseType', 'blob');

    return this.http.get(environment.GearsApi_url + url, {
      responseType: 'blob',
      headers: headerObj,
    });
  }

  save(url: string, body: any): Observable<any> {
    const requestUrl = environment.GearsApi_url + url;
    return this.http.post(requestUrl, body, {
      observe: 'response',
    });
  }

  saveAll(url: string, id: number, body: any): Observable<any> {
    const requestUrl = environment.GearsApi_url + url + '/' + id;
    return this.http.put(requestUrl, body, {
      observe: 'response',
    });
  }

  uploadFile(
    url: string,
    file: any,
    description: string
  ): Observable<HttpResponse<any>> {
    const requestUrl = environment.GearsApi_url + url;

    const formData = new FormData();
    formData.append('file', file);
    formData.append('sDescription', description);

    return this.http.post(requestUrl, formData, {
      observe: 'response',
      responseType: 'text',
    });
  }
}
