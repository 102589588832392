import { Component, Input, NgModule, OnInit } from '@angular/core';
import { GearsApiService } from 'src/app/services/gearsapi.service';
import { ViewChild } from '@angular/core';
import { DragScrollComponent, DragScrollItemDirective } from 'ngx-drag-scroll';
import { SitesService } from 'src/app/services/sites.service';
import { environment } from 'src/environments/environment';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'app-dynamicslider',
  templateUrl: './dynamicslider.component.html',
  styleUrls: ['./dynamicslider.component.less']
})
export class DynamicsliderComponent implements OnInit {

  constructor(private apiservice: GearsApiService, private siteService: SitesService) { }
  public current = 0;
  loading:boolean = true;
  FeatureImgClass: any;
  currentIndex: number = 0;
  @Input() features: any = [];
  @ViewChild('badges', { read: DragScrollComponent }) ds!: DragScrollComponent;
  ngOnInit(): void {
    this.loading = false;
  }
  imgadd(url: string) {
    if (!url.includes('http')) {
      return environment.storageurl + url;
    }
    else {
      return url;
    }

  }
  next() {
    this.ds.moveRight();
    if (this.currentIndex < this.features.length) {
      this.currentIndex++;
    }
  }

  prev() {
    this.ds.moveLeft();
    if (this.currentIndex > 0) {
      this.currentIndex--;
    }
  }

  currentSlide(index : number) {
    this.currentIndex = index;
    this.ds.moveTo(index);
  }

  foo(x: any) {
    this.currentSlide(x-1);
    this.currentSlide(x);
  }

}

@NgModule({
  declarations: [
    DynamicsliderComponent,
  ],
  imports:[
    CommonModule,
    DragScrollComponent,
    DragScrollItemDirective
    //DragScrollModule
  ],
  exports: [
    DynamicsliderComponent
  ]
})
export class DynamicsliderModule { }